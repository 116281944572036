import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from './navbar.module.css'
import { useState, useEffect } from 'react';

function NavBar({ onLinkClick }) {

    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }
        window.addEventListener("scroll", onScroll)

        return () => window.removeEventListener("scroll", onScroll);
    }, [])
    return (
        <Navbar expand="sm" className={scrolled ? "scrolled " : ""}  >
            <Container className={styles.container}>
                <div className={styles.name}>
                    Ronghui Zhong
                    <div className={styles.chinesename}>
                        <i className='iconfont icon-ditu'></i>CHANGSHA CN | NEWCASTLE UK
                    </div>
                </div>
                <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    style={{ backgroundColor: '#D0D0D0' }}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className={`me-auto ${styles.nav}`}>
                        <Nav.Link href="#home"
                            className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
                            onClick={() => { setActiveLink('about'); onLinkClick('about') }}>About</Nav.Link>
                        <Nav.Link href="#link"
                            className={activeLink === 'activities' ? 'active navbar-link' : 'navbar-link'}
                            onClick={() => { setActiveLink('activities'); onLinkClick('activities') }}>Activities</Nav.Link>
                        <Nav.Link href="#link"
                            className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'}
                            onClick={() => { setActiveLink('projects'); onLinkClick('projects') }}>Projects</Nav.Link>
                        <Nav.Link href="#link"
                            className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'}
                            onClick={() => { setActiveLink('contact'); onLinkClick('contact') }}>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
}

export default NavBar;