
import { Space } from 'antd';
import styles from './styles.module.css'
import Me from '../../../assets/img2024_8/contact.png'
import Outlook from '../../../assets/img2024_8/Outlook.png'
import Phone from '../../../assets/img2024_8/phone.png'
import { motion } from 'framer-motion';
export default function Contact() {
    return (
        <>
            <motion.div
                initial={{ x: '-100vw', opacity: 0 }}  // 从左侧完全不可见的位置开始
                animate={{ x: 0, opacity: 1 }}          // 动画效果到达目标位置
                exit={{ x: '-100vw', opacity: 0 }}      // 退出动画效果
                transition={{ duration: 1.5, ease: 'easeOut' }}  // 延长持续时间并使用缓动效果
                className={styles.container}>
                <div className={styles.picFrame}>
                    <img src={Me} className={styles.pic} />
                </div>
                <div
                    onClick={
                        () => { window.location.href = "mailto:anxelswanz@outlook.com?subject=Hello%20Ronghui&body=I%20would%20like%20to%20talk%20about..." }
                    }
                    className={styles.text}>
                    <img src={Outlook} className={styles.icon} />
                    <span className={styles.email}>anxelswanz@outlook.com</span>
                    <div className={styles.phone}> <img src={Phone} className={styles.phoneIcon} />+44 07783172674</div>
                </div>
            </motion.div>
        </>
    );
}