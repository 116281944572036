import { Row, Col, Menu } from "antd";
import { useState } from "react";
import styles from './styles.module.css'
import Activities2023 from "../ActivitiesComponents/Activities2023/Activities2023";
import Activities2024 from "../ActivitiesComponents/Activities2024/Activities2024";
import { motion } from 'framer-motion';
export default function Activities() {
    const contentStyle = {
        margin: '0 auto',
        height: '16rem',
        width: '24rem',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
        borderRadius: '1rem',
        opacity: '0.85'
    };

    const [year, setYear] = useState('2024');
    const [target, setTarget] = useState('0');

    const items = [
        {
            key: '2024',
            label: '2024',
            children: [
                { key: '0', label: 'MSc Computer Science' },
                { key: '1', label: 'British Council Study UK Ambassador' },
                { key: '2', label: 'NUSU Commercial Team' }
            ],
        },
        {
            key: '2023',
            label: '2023',
            children: [

                { key: '0', label: 'Zuo You Technology - Back-End Software Engineer' },
                { key: '1', label: 'Dasuanpan Digital Technology Co., Ltd - Back-End Software Engineer Intern' },
            ],
        }
    ];

    function onClick(e) {
        let arr = e.keyPath;
        console.log(arr);
        setYear(arr[1]);
        setTarget(arr[0]);
        console.log(arr[1]);
    }



    return (
        <>
            <Row>
                <Col>
                    <motion.div
                        initial={{ x: '-100vw', opacity: 0 }}  // 从左侧完全不可见的位置开始
                        animate={{ x: 0, opacity: 1 }}          // 动画效果到达目标位置
                        exit={{ x: '-100vw', opacity: 0 }}      // 退出动画效果
                        transition={{ duration: 1.5, ease: 'easeOut' }}  // 延长持续时间并使用缓动效果
                        className={styles.content}>
                        <div className={styles.menu}>
                            <Menu
                                className="menu"
                                onClick={onClick}
                                style={{ width: 256 }}
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                mode="inline"
                                items={items}
                            />
                        </div>
                        <div className={styles.projects}>
                            {year === '2024' && <Activities2024 target={target} />}
                            {year === '2023' && <Activities2023 target={target} />}
                        </div>
                    </motion.div>
                </Col>
            </Row>
        </>
    )
}